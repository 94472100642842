const state = {
    token:null,
    dateToken: null,
    isWorking: false,
    isWorkingAdminModals:false,

    isShowModalFirma:false, //indica si se muestra el modal donde el usuario puede firmar un documento desde Mis Documentos > Por Firmar
    isShowModalDocAdmin:false, //indica si se muestra el modal donde el usuario administra el documento

    perfilUsuario:null,
    perfilUsuarioPublico:null,
    
    msgUsuario:{
        codigo:"",
        mensaje:"",
        causa:""
    },
    verMsgUsuario:false,
    
    docNvo:null,
    docAdmin:null,
    docPublico:null,

    docsPorFirmar:null,

    docsCreados:null,

    pagosCuenta:{
        creditos:300,
        pagos:[
            {
                ordenPago:"AZ000003",
                fechaPago:"23/05/2009",
                concepto:"Compra 5 documento(s)",
                monto:"2,456.00",
                facturado:'Si',
                acciones:['facturar','ver factura'],
            },
            {
                ordenPago:"AZ0000024",
                fechaPago:"01/05/2009",
                concepto:"Compra 1 documento(s)",
                monto:"234.00",
                facturado:'No',
                acciones:['facturar','ver factura'],
            }
        ]
    },

    principalVariant:"uva",
    secondaryVariant:"info",
    styleLabelColor:'color:#6f42c1;',
    baseColor:'#6f42c1',

    verCollapseDoc:true,
    verCollapseParts:false,
    verCollapseFirma:false,

    verDocumentoIndividual:false, 
    verProcesoPublico:false,
    verDocumentosMasivos:false,
    verDocumentosPendientes:false,
    verDocumentosCreados:false,
    verDatosCuenta:false,
    verPagos:false,
    verApi:false,

    verMjeConfirmaProceso:false,
    mjeConfirmaProceso:"",
    
}

// mutations
const mutations = {
    configVistaPerfil(state, config){
        state.verDocumentoIndividual=config.verDocumentoIndividual;
        state.verProcesoPublico=config.verProcesoPublico;
        state.verDocumentosMasivos=config.verDocumentosMasivos;
        state.verDocumentosPendientes=config.verDocumentosPendientes;
        state.verDocumentosCreados=config.verDocumentosCreados;
        state.verDatosCuenta=config.verDatosCuenta;
        state.verPagos=config.verPagos;
        state.verApi=config.verApi;
    },
    updateToken(state, token){
        state.token = 'Bearer '+token;
    },
    updatePerfilUsuario(state, perfil){
        state.perfilUsuario = perfil;
        state.perfilUsuarioPublico = perfil;
    },
    updatePerfilUsuarioPublico(state, perfil){
        state.perfilUsuarioPublico = perfil;
    },
    updateIsWorking(state, newIs){
        state.isWorking = newIs;
    },
    updateIsShowModalFirma(state, newIs){
        state.isShowModalFirma = newIs; 
    },
    updateIsShowModalDocAdmin(state, newIs){
        state.isShowModalDocAdmin = newIs;
    },
    updateMsgUsuario(state, newMU){
        state.msgUsuario = newMU;
    },
    updateVerMsgUsuario(state, ver){
        state.verMsgUsuario = ver;
    },
    updateVerMjeConfirmaProceso(state, newVal){
        state.verMjeConfirmaProceso = newVal;
    },
    updateMjeConfirmaProceso(state, mensaje){
        state.mjeConfirmaProceso = mensaje;
    },
    updateDocNvo(state){
        const fecExp = new Date(new Date().getTime()+(7*24*60*60*1000));
        const fecExpString = fecExp.toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'}).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');

        state.docNvo ={
            idDocumento:null, //necesita estar en null, de esta forma se sabe que el documento aun no ha sido creado
            estado:"Nuevo", //la descripciondebe coincidir con el servidor
            numEtapas:1,
            nombre:null, //necesita estar en null porque asi sabemos que no hay archivo
            comentarios:"",
            fecCreado:"",
            fecFirmado:"",
            archivo:null,
            hash:null,
            encriptado:false,
            fecNom151:null,//necesita estar en null
            nombrePsc:null,//necesita estar en null
            incluirNom151:true,//por el momento todo lleva Nom151
            incluirNom151Acuse:false,
            fecExpiracion:fecExpString,
            numDiasNotificar:'No notificar',//la descripciondebe coincidir con el servidor
            correoCreador:state.perfilUsuario.correoBase,
            participantes:[
                
            ],
        }
    },
    eliminarDocNvo(state){
        state.docNvo=null;
    },
    updateFecExpiracionDocNvo(state, newVal){
        state.docNvo.fecExpiracion=newVal;
    },
    updateNumDiasNotificarDocNvo(state, newVal){
        state.docNvo.numDiasNotificar=newVal;
    },
    updateArchivoInDocNvo(state, documento){
        state.docNvo.archivo=documento.archivo;
        state.docNvo.nombre=documento.nombre;
        state.docNvo.hash=null;
        //alert(archivo);
    },
    updateHashInDocNvo(state, hash){
        state.docNvo.hash=hash;
        //alert(archivo);
    },
    agregarParticipanteDocNvo(state, part){
        //Solo si no existe otro participante con el mismo correo en la misma etapa
        var existe=false;
        for(var p=0; p<state.docNvo.participantes.length; p++){
            if(state.docNvo.participantes[p].correo == part.correo && state.docNvo.participantes[p].numEtapa == part.numEtapa){
                existe=true;
            }
        }
        if(existe){
            state.msgUsuario ={
                codigo:"1900", //cualquiera mayor a 1900 y menor a 200
                mensaje: "No fue posible agregar a " + part.nombre,
                causa:"Ya existe otra persona en los participantes con el mismo correo"
            };
            state.verMsgUsuario=true;
        }else{
            var participante ={
                nombre:part.nombre,
                correo:part.correo,
                idNacional:part.idNacional,
                tipoFirma:part.tipoFirma    ,
                tipoParticipante:part.tipoParticipante,
                numEtapa:part.numEtapa,
                estado:"Pendiente de firmar"
            }
            state.docNvo.participantes.push(participante);
        }
    },
    agregarEtapaDocNvo(state){
        var numActual = state.docNvo.numEtapas;
        var hayFirmantesAnt = false;
        for(var p=0; p<state.docNvo.participantes.length; p++){
            if(state.docNvo.participantes[p].numEtapa == numActual){
                hayFirmantesAnt = true;
            }
        }    
        if(hayFirmantesAnt){
            state.docNvo.numEtapas = numActual+1;
        }else{
            state.msgUsuario ={
                codigo:"1900", //cualquiera mayor a 1900 y menor a 200
                mensaje:"La Etapa "+numActual+' se encuentra vacía',
                causa:"Para agregar un nueva etapa debes agregar al menos un participante en la Etapa "+numActual
            };
            state.verMsgUsuario=true;
        }
    },
    eliminarEtapaDocNvo(state, numEtapa){
        var numActual = state.docNvo.numEtapas;
        if(numEtapa!=1){
            for(var p=0; p<state.docNvo.participantes.length; p++){
                if(state.docNvo.participantes[p].numEtapa == numEtapa){
                    //borrar
                    state.docNvo.participantes.splice(p, 1);
                }else{
                    //restarle 1 solo si no es la ultima etapa la que se esta borrando
                    if(numEtapa!=numActual){
                        var etapa = state.docNvo.participantes[p].numEtapa;
                        state.docNvo.participantes[p].numEtapa = etapa-1;
                    }
                }
            }
            //cambiar el numero de etapas
            state.docNvo.numEtapas = numActual-1;
        }
    },
    eliminarParticipante(state, correo){
        for(var p=0; p<state.docNvo.participantes.length; p++){
            if(state.docNvo.participantes[p].correo == correo ){
                //borrar 
                state.docNvo.participantes.splice(p, 1);
            }
        }        
    },
    configCollapse(state, config){
        state.verCollapseDoc=config.verCollapseDoc;
        state.verCollapseParts=config.verCollapseParts;
        state.verCollapseFirma=config.verCollapseFirma;
        state.verCollapseConstancia=config.verCollapseConstancia;
    },
    updateDocAdmin(state, doc){
        state.docAdmin = doc;
    },
    updateDocPublico(state, doc){
        state.docPublico = doc;
    },
    updateDocsPorFirmar(state, docs){
        state.docsPorFirmar=docs;        
    },
    updateDocsCreados(state, docs){
        state.docsCreados=docs;        
    },
    quitarDocumentoFromCreados(state, idDocumento){
        for(var i=0; i<state.docsCreados.length; i++){
            if(state.docsCreados[i].idDocumento==idDocumento){
                state.docsCreados.splice(i, 1);
            }
        }
    },
    updatePagosCuenta(state, pagos){
        state.pagosCuenta=pagos
    }
    
}

export default {
    state,
    mutations
}