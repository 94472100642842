<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
}
</script>

<style lang="scss">
  // Import custom SASS variable overrides, or alternatively
  // define your variable overrides here instead
  @import "../node_modules/bootstrap/scss/_functions";
  @import "../node_modules/bootstrap/scss/_mixins";
  @import 'assets/custom-variables.scss';

  // Import Bootstrap and BootstrapVue source SCSS files

  @import '../node_modules/bootstrap/scss/bootstrap.scss';
  @import '../node_modules/bootstrap-vue/src/index.scss';


.div.error{
  color: red;
}
.inner-container-5{
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.inner-container-15{
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.border-tab{
  border-bottom: 3px #6f42c1 solid !important;
}
.active-tab{
  background-color: #979797  !important;
}
.card-shadow{
  margin-top: 15px;
  box-shadow: 4px 3px 15px 1px #7C7C7C;
  border-radius: 10px;
  background-color: white;
}
.card-part{
  padding-top: 10px ;
  padding-bottom: 5px ;
  padding-left: 15px ;
  padding-right: 25px ;
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border: #dbdbdb solid 1px;
  box-shadow: 4px 4px 5px 1px #cecece;
  border-radius: 8px;
}
div.error {color: red;}

.border-free{
  border: 1px gray solid;
  
}

</style>