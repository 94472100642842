import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueRouter from 'vue-router'
import Vuelidate from "vuelidate"
import store from './store'
import './registerServiceWorker'


Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueRouter)
Vue.use(Vuelidate)

/* Variables estaticas que deben coincidir con las declaradas en el servidor*/
Vue.prototype.$estadoDocNuevo= 'Nuevo'
Vue.prototype.$estadoDocVoBo= 'Por revisar'
Vue.prototype.$estadoDocConcluido= 'Concluido'
Vue.prototype.$numEtapaVoBo= 0
Vue.prototype.$numEtapaAcuse= 99
Vue.prototype.$numEtapaObs= 100
Vue.prototype.$tipoVoBo= 'Verificador'
Vue.prototype.$tipoFirmante='Firmante'
Vue.prototype.$tipoAcuse='Receptor'
Vue.prototype.$tipoObservador='Observador'
Vue.prototype.$firmaSimple='Simple'
Vue.prototype.$firmaAvanzada='Avanzada'
Vue.prototype.$numMaxVoBo=3
Vue.prototype.$numMaxEtapasFirma=5
Vue.prototype.$numMaxFirmantesEtapas=100
Vue.prototype.$numMaxAcuse=1
Vue.prototype.$numMaxObs=10
Vue.prototype.$numMaxDiasVigencia=30
Vue.prototype.$frecuenciasAvisos=['No notificar', 'Cada 15 días', 'Cada 7 días', 'Cada 3 días', 'Diario' ]
Vue.prototype.$estadosDocumento=['Por revisar','Pendiente de firma','Pendiente de Acuse','Concluido']

var router = new VueRouter({
  routes: [
    /*Usuario*/
    {
      path: '/',
      component: () => import('@/components/user/FirmaClaro.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/index.html',
      component: () => import('@/components/user/FirmaClaro.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    /*Consulta publica del documento*/
    {
      path: '/rev/:version',
      component: () => import('@/components/user/FirmaClaro.vue'),
      meta: { 
        requiresAuth: false
      }
    }

  ],
  mode: "history"
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
